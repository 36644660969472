import { lazy } from "react";
import { layoutsTypes } from "./layouts";

const splash = [
  {
    layout: layoutsTypes.PUBLIC,
    path: ["/"],
    exact: true,
    roles: [], //the roles that can use this route <- not currently using roles
    isAnonymous: true,
    component: lazy(() => import("@pages/Splash")),
  },
];

const contact = [
  {
    layout: layoutsTypes.PUBLIC,
    path: ["/contact"],
    exact: false,
    roles: [],
    isAnonymous: true,
    component: lazy(() => import("@pages/Contact")),
  },
];

const about = [
  {
    layout: layoutsTypes.PUBLIC,
    path: ["/about"],
    exact: false,
    roles: [],
    isAnonymous: true,
    component: lazy(() => import("@pages/About")),
  },
];

const forum = [
  {
    layout: layoutsTypes.PUBLIC,
    path: ["/forum"],
    exact: false,
    roles: [],
    isAnonymous: true,
    component: lazy(() => import("@pages/Error500")),
  },
];

const style = [
  {
    layout: layoutsTypes.PUBLIC,
    path: ["/style"],
    exact: false,
    roles: [],
    isAnonymous: true,
    component: lazy(() => import("@pages/Error500")),
  },
];

const invest = [
  {
    layout: layoutsTypes.PUBLIC,
    path: ["/invest"],
    exact: false,
    roles: [],
    isAnonymous: true,
    component: lazy(() => import("@pages/Error500")),
  },
];

const faq = [
  {
    layout: layoutsTypes.PUBLIC,
    path: ["/faq"],
    exact: false,
    roles: [],
    isAnonymous: true,
    component: lazy(() => import("@pages/Error500")),
  },
];

const termsOfUse = [
  {
    layout: layoutsTypes.PUBLIC,
    path: ["/termsOfUse"],
    exact: false,
    roles: [],
    isAnonymous: true,
    component: lazy(() => import("@pages/TermsOfUse")),
  },
];

const errorRoutes = [
  {
    layout: layoutsTypes.PUBLIC,
    path: ["/error/404"],
    exact: true,
    roles: [],
    isAnonymous: false,
    component: lazy(() => import("@pages/Error404")),
  },
];

const unauthorizedRoutes = [
  {
    layout: layoutsTypes.PUBLIC,
    path: ["/unauthorized/403"],
    exact: true,
    roles: [],
    isAnonymous: false,
    component: lazy(() => import("@pages/Error403")),
  },
];

var publicRoutes = [
  ...splash,
  ...contact,
  ...about,
  ...forum,
  ...style,
  ...invest,
  ...faq,
  ...errorRoutes,
  ...unauthorizedRoutes,
  ...termsOfUse,
];

export default publicRoutes;
