import './firebase/config'; // Ensure this is the correct path to our Firebase configuration file
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "./fonts/Lobster/Lobster-Regular.ttf";
import "./services/serviceHelper";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/dist/sweetalert2.min.css";
// import { ToastContainer } from "react-toastify";
// test
import CssBaseline from "@material-ui/core/CssBaseline";
import ScrollToTop from "./utils/ScrollToTop";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

ReactDOM.render(
  <BrowserRouter basename="/">
    <CssBaseline />
    <ScrollToTop>
      <App />
    </ScrollToTop>
    {/* <ToastContainer /> */}
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
