import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom'; // Import useParams for firestore
import axios from 'axios';  // Import axios for making HTTP requests
import './ReviewAsk.css';
import { projectFirestore as db, projectStorage as storage } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import logo from '../assets/Tangerine_Icon_blue_grad_overall_TM.png';  // Import the logo

// may want to check if the Q is an Ask or Compare again and if it's a Compare, reroute the member to /reviewcompare

const ReviewAsk = () => {
  const [selection, setSelection] = useState('');
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [imageUrl, setImageUrl] = useState('');  // State to store the image URL
  const [captionText, setCaptionText] = useState(''); // State to store the caption text
  const [displayName, setDisplayName] = useState('they');
  const [error, setError] = useState('');
  const [voteSent, setVoteSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { docId } = useParams(); // Use useParams to get the docId from URL
  const history = useHistory();


  // Function to fetch image URL from Firestore and Storage
  const fetchImageUrl = useCallback(async () => {
    const docRef = doc(db, "PENDING_ONLINE_REVIEWS", docId);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const pendingOnlineReviewDoc = docSnap.data();
        const isAsk = pendingOnlineReviewDoc.isAsk;
        if (!isAsk) {
          history.push(`/reviewcompare/${docId}`);
          return;
        }
        const imageRelativePath = pendingOnlineReviewDoc.imageURL_1;
        const imageStorageRef = ref(storage, imageRelativePath);
        const url = await getDownloadURL(imageStorageRef);
        setImageUrl(url);
        setCaptionText(pendingOnlineReviewDoc.captionText_1);
        setDisplayName(pendingOnlineReviewDoc.displayName || 'they');
      } else {
        setError("Vote already counted or invite expired.");
      }
    } catch (error) {
      setError("Error fetching image: " + error.message);
    }
  }, [docId, history]);



  useEffect(() => {
    console.log("just called useEffect");
    if (docId) {
      fetchImageUrl();
    }
  }, [docId, fetchImageUrl]);


  const handleVote = (vote) => {
    setSelection(vote);
  };

  const toggleMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  // sendVote method for HTTP triggered function
  const sendVote = async () => {
    if (!selection) return;
    setIsLoading(true);
    try {
      const response = await axios.post('https://us-central1-fir-poc-1594b.cloudfunctions.net/createOnlineReview', {
        pendingOnlineReviewName: docId,
        selection: selection
      }, {
        withCredentials: true
      });
      console.log('Response from Cloud Function:', response.data);
      setTimeout(() => {
        setVoteSent(true);
        setIsLoading(false);
        setTimeout(() => {
          window.location.href = 'https://apps.apple.com/us/app/tangerine-insight/id1570304842';
        }, 1000);
      }, 1000); // Duration of the animation
    } catch (error) {
      console.error('Error sending vote:', error);
      setIsLoading(false);
    }
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="review-ask">
            <div className="tangerine-insight">
        <span>Powered by Tangerine</span>
        <img src={logo} alt="App Logo" className="logo" />
      </div>
      <h1>Should {displayName} wear this item?</h1>
      <div className="info-section">
        <p>Your response is anonymous <span className="info-link" onClick={toggleMoreInfo}>(i)</span></p>
      </div>
      <div>
        {showMoreInfo && (
          <p className="additional-info">
            They will receive a generalized recommendation from us on whether to wear it based on your vote, and input from everyone else who votes too.
          </p>
        )}
      </div>
      <div className="photo-placeholder">
        {imageUrl ? <img src={imageUrl} alt="User Submission" /> : "Loading image..."}
        {captionText && <div className="image-caption">{captionText}</div>}
      </div>
      <div className="button-container">
        <button
          className={`button no ${selection === 'no' ? 'selected' : ''}`}
          onClick={() => handleVote('no')}
        >
          NO! Don't wear
        </button>
        <button
          className={`button yes ${selection === 'yes' ? 'selected' : ''}`}
          onClick={() => handleVote('yes')}
        >
          YES! Wear it
        </button>
      </div>
      <button
        className={`send-vote ${isLoading ? 'loading' : ''} ${voteSent ? 'sent' : ''}`}
        disabled={!selection || isLoading}
        onClick={sendVote}
      >
        {isLoading ? 'Sending vote...' : (voteSent ? 'VOTE SENT!' : 'SEND YOUR VOTE')}
      </button>{/* Button is disabled if no selection is made */}
    </div>
  );
};

export default ReviewAsk;
