import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "firebase/compat/auth";

// import { initializeApp } from 'firebase/app';
// import { getFirestore } from 'firebase/firestore';
// import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBbC20COYSmUlrj2GnbNAdqZ4SmNifMfwU",
  authDomain: "fir-poc-1594b.firebaseapp.com",
  // databaseURL: "https://fir-poc-1594b.firebaseio.com",
  projectId: "fir-poc-1594b",
  storageBucket: "fir-poc-1594b.appspot.com",
  messagingSenderId: "48111823222",
  appId: "1:48111823222:web:2f9e46a4d2d8f9512bb257",
  measurementId: "G-02WP3WZZWM",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//   firebase.analytics();

const projectStorage = firebase.storage(); //This is access to our project storage
const projectFirestore = firebase.firestore(); //This is access to our project database
const timestamp = firebase.firestore.FieldValue.serverTimestamp; //This is a firebase server timestamp function
const auth = firebase.auth(); //This is access to authentication


// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);
// const storage = getStorage(app);

// export { db, storage };


export { projectStorage, projectFirestore, timestamp, auth };
