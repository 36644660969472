import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';  // Import axios for making HTTP requests
import { projectFirestore as db, projectStorage as storage } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage'; // Import necessary for handling Firebase storage
import logo from '../assets/Tangerine_Icon_blue_grad_overall_TM.png';  // Import the logo
import './ReviewCompare.css';

const ReviewCompare = () => {
  const [selection, setSelection] = useState('');
  const [topImage, setTopImage] = useState('');
  const [bottomImage, setBottomImage] = useState('');
  const [topCaption, setTopCaption] = useState('');
  const [bottomCaption, setBottomCaption] = useState('');
  const [displayName, setDisplayName] = useState('they');
  const [error, setError] = useState('');
  const [voteSent, setVoteSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { docId } = useParams();
  const history = useHistory();


  const fetchImagesAndCaptions = useCallback(async () => {
    const docRef = doc(db, "PENDING_ONLINE_REVIEWS", docId);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const pendingOnlineReviewDoc = docSnap.data();
        const isAsk = pendingOnlineReviewDoc.isAsk;
        if (isAsk) {
          history.push(`/reviewask/${docId}`);
          return;
        }
        const topImagePath = ref(storage, pendingOnlineReviewDoc.imageURL_1);
        const bottomImagePath = ref(storage, pendingOnlineReviewDoc.imageURL_2);

        const topImageUrl = await getDownloadURL(topImagePath);
        const bottomImageUrl = await getDownloadURL(bottomImagePath);

        setTopImage(topImageUrl);
        setBottomImage(bottomImageUrl);
        setTopCaption(pendingOnlineReviewDoc.captionText_1);
        setBottomCaption(pendingOnlineReviewDoc.captionText_2);
        setDisplayName(pendingOnlineReviewDoc.displayName || 'they');
      } else {
        setError("Vote already counted or invite expired.");
      }
    } catch (error) {
      setError("Error fetching document: " + error.message);
    }
  }, [docId, history]);

  useEffect(() => {
    fetchImagesAndCaptions();
  }, [docId, fetchImagesAndCaptions]);

  const handleImageClick = (image) => {
    setSelection(image);
  };

    // sendVote method for HTTP-triggered function
    const sendVote = async () => {
      if (!selection) return;
      setIsLoading(true);
      try {
        const response = await axios.post('https://us-central1-fir-poc-1594b.cloudfunctions.net/createOnlineReview', {
          pendingOnlineReviewName: docId,
          selection: selection
        }, {
          withCredentials: true
        });
        console.log('Response from Cloud Function:', response.data);
        setTimeout(() => {
          setVoteSent(true);
          setIsLoading(false);
          setTimeout(() => {
            window.location.href = 'https://apps.apple.com/us/app/tangerine-insight/id1570304842';
          }, 1000);
        }, 1000); // Duration of the animation
      } catch (error) {
        console.error('Error sending vote:', error);
        setIsLoading(false);
      }
    };
  
    if (error) {
      return <div className="error">{error}</div>;
    }

  return (
    <div className="review-compare">
        <div className="tangerine-insight">
          <span>Powered by Tangerine</span>
          <img src={logo} alt="App Logo" className="logo" />
      </div>
      <div
        className={`image-view ${selection === 'top' ? 'selected' : ''}`}
        onClick={() => handleImageClick('top')}
      >
        {topImage ? <img src={topImage} alt="Top Submission" /> : "Loading top image..."}
        {topCaption && <div className="image-caption">{topCaption}</div>} {/* Only render if topCaption has content */}
      </div>
      <h5>Tap the one {displayName} should wear</h5>
      <p className="anonymous">Your response is anonymous. (i)</p>
      <div
        className={`image-view ${selection === 'bottom' ? 'selected' : ''}`}
        onClick={() => handleImageClick('bottom')}
      >
        {bottomImage ? <img src={bottomImage} alt="Bottom Submission" /> : "Loading bottom image..."}
        {bottomCaption && <div className="image-caption">{bottomCaption}</div>} {/* Only render if bottomCaption has content */}
      </div>
      <button 
      className={`send-vote ${isLoading ? 'loading' : ''} ${voteSent ? 'sent' : ''}`}
      disabled={!selection || isLoading}
      onClick={sendVote}
      >
        {isLoading ? 'Sending vote...' : (voteSent ? 'VOTE SENT!' : 'SEND YOUR VOTE')}
        
      </button> {/* Button is disabled if no selection is made */}

    </div>
  );
};

export default ReviewCompare;
