import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { ButtonBase } from "@material-ui/core";

// import tangerineLogo from "@assets/32344-tangerine-icon.png";
import tangerineLogo from "@assets/Tangerine.slice-01.png";
import AppStoreImage from "@assets/AppStoreDownloadImage.png";

import debug from "debug";
const _logger = debug.extend("TopNavbar");

const TopNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onClickAppStore = (e) => {
    e.preventDefault();
    _logger("Clicked app store icon.");
    window
      .open(
        "https://apps.apple.com/us/app/tangerine-insight/id1570304842?itsct=apps_box_link&itscg=30200",
        "_blank"
      )
      .focus();
  };

  return (
    <Navbar className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark w-100 py-0 px-2">
      <NavbarBrand href="/">
        <div style={{ height: "50px" }}>
          <img src={tangerineLogo} className="h-100" alt="Tangerine Icon" />
          <span
            className="font-face-lobster"
            style={{ verticalAlign: "middle", fontSize: "x-large" }}
          >
            Tangerine
          </span>
          {/* 
            <ButtonBase className="" onClick={onClickAppStore}>
              <img
                src={AppStoreImage}
                className=""
                style={{ maxHeight: "25px" }}
                alt="Apple App Store Link"
              />
          </ButtonBase> */}
        </div>
        <div style={{ fontSize: "medium", color: "#ffab40" }}>
          The Ultimate Second Opinion
        </div>
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <NavLink href="/" className="mainNavbarButton">
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/contact" className="mainNavbarButton">
              Contact
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/about" className="mainNavbarButton">
              About
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/forum" className="mainNavbarButton">
              Fashion Forum
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/style" className="mainNavbarButton">
              Style
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/invest" className="mainNavbarButton">
              Investors
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="/faq"
              className="mainNavbarButton"
              style={{ paddingRight: "16px" }}
            >
              FAQ
            </NavLink>
          </NavItem>
          <NavItem style={{ lineHeight: "31px" }}>
            <ButtonBase className="" onClick={onClickAppStore}>
              <img
                src={AppStoreImage}
                className=""
                style={{ maxHeight: "25px" }}
                alt="Apple App Store Link"
              />
            </ButtonBase>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default TopNavbar;
