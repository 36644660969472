import React, { Component, Suspense, Fragment } from "react";
import {
  // BrowserRouter,
  Switch,
  Route,
  withRouter,
  // Redirect,
} from "react-router-dom";
// import Firebase from "firebase";
// import firebaseConfig from "./config.js";

import { RouterProps } from "./config/sitePropTypes";
import { AnimatePresence, motion } from "framer-motion";

import { RingLoader } from "react-spinners";

// import Routes from "./Routes";
import "./App.css";
import applicationRoutes from "./routes/allRoutes";
import TopNavbar from "@layout/TopNavbar";

import SocialMedia from "@layout/SocialMedia";

import Error403 from "@pages/Error403";
import Error404 from "@pages/Error404";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

// this page will be used to display Questions sent to Unregistered Users
import ReviewAsk from './components/ReviewAsk';
import ReviewCompare from './components/ReviewCompare';


import debug from "debug";
const _logger = debug.extend("App");

library.add(fab, faArrowLeft);

const DEFAULT_USER = {
  roles: [],
  userName: "",
  email: "",
  avatarUrl: "",
};

class App extends Component {
  constructor(props) {
    super(props);
    // Firebase.initializeApp(firebaseConfig);

    let isAnonymous = true;

    const routes = this.getRoutes(
      applicationRoutes.byLayout,
      DEFAULT_USER,
      isAnonymous
    );

    this.state = {
      currentUser: DEFAULT_USER,
      routes,
      isLoggedIn: false,
      loading: true,
      isAnonymous,
    };
  }

  pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99,
    },
    in: {
      opacity: 1,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.01,
    },
  };

  pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.4,
  };

  componentDidMount = () => {
    // Future development when users can log in to the site....
    // if (!this.state.isLoggedIn) {
    //   getCurrentUser()
    //     .then(this.onGetCurrentUserSuccess)
    //     .catch(this.onGetCurrentUserError);
    // }
    this.setState({ loading: false });
  };

  getRoutes = (routesByType, currentUser, isAnonymous) => {
    const route = {};
    for (const [key, routeEntries] of Object.entries(routesByType)) {
      //isAnonymous has no roles checks. gets everything that is isAnonymous === true or no roles
      if (isAnonymous) {
        let filteredEntries = routeEntries.filter((ent) => ent.isAnonymous);
        //let filteredEntries = routeEntries;
        route[key] = filteredEntries.map(this.getRouteMapper(currentUser));
        var paths = filteredEntries.reduce(this.collectPaths, []);
        route[key].paths = paths;
      } else {
        //Logic for calculating routes when there are roles for a currentUser we should consider
        // let userRoles = currentUser.roles.map((roles) => roles.name);
        // let filteredEntries = routeEntries.filter((route) => {
        //   return route.roles.some((role) => userRoles.includes(role));
        // });
        // route[key] = filteredEntries.map(this.getRouteMapper(currentUser));
        // route[key].paths = filteredEntries.reduce(this.collectPaths, []);
      }
    }
    return route;
  };

  collectPaths = (accumulator, currentValue) => {
    accumulator.push(...currentValue.path);
    return accumulator;
  };

  filterAnonymous = (route) => {
    return route.isAnonymous;
  };

  getRouteMapper = (currentUser) => {
    return (routeData) => {
      let Component = routeData.component; //notice this variable and how it is used below
      return (
        <Route
          key={routeData.path}
          path={routeData.path}
          exact={routeData.exact}
          render={(props) => <Component {...props} currentUser={currentUser} />}
        />
      );
    };
  };

  getRouteExists = (currentLocation) => {
    const routes = applicationRoutes.allRoutes;
    const mappedRoutes = routes.map((routes) => routes.path).flat(1);
    const currentLocationExists = mappedRoutes.includes(currentLocation);
    return currentLocationExists;
  };

  unauthorizedRedirect = () => {
    return <Route component={Error403} />;
  };

  SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <RingLoader color={"#ffa000"} loading={true} size={150} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we load Tangerine.
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    if (this.state.loading) {
      return <RingLoader color={"#ffa000"} loading={true} size={150} />;
    }
    const routeExists = this.getRouteExists(this.props.location.pathname);
    _logger(`Route exists: ${routeExists}`);

    return (
      <AnimatePresence>
        <Suspense fallback={<this.SuspenseLoading />}>
          <Switch>
            {/* We added the route for ReviewAsk here so that it's checked first */}
            {/* This will display the ReviewAsk component when '/reviewask' is the URL path */}
            {/* //This is the old link setup before linking to firestore:
            <Route path="/reviewask" component={ReviewAsk} exact />*/}
            <Route path="/reviewask/:docId" component={ReviewAsk} exact />

            <Route path="/reviewcompare/:docId" component={ReviewCompare} exact />
            {/* Existing route for public paths */}
            <Route path={this.state.routes.public.paths} exact>
              <div className="background"></div>
              <TopNavbar />
              <div className="body">
                <Switch
                  location={this.props.location}
                  key={this.props.location.pathname}
                >
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={this.pageVariants}
                    transition={this.pageTransition}
                  >
                    {this.state.routes.public}
                  </motion.div>
                </Switch>
              </div>
              <SocialMedia />
            </Route>
            {/* Handling unauthorized or non-existent routes */}
            {routeExists ? (
              this.unauthorizedRedirect()
            ) : (
              <Route component={Error404} />
            )}
          </Switch>
        </Suspense>
      </AnimatePresence>
    );
  }
}

App.propTypes = {
  ...RouterProps,
};

export default withRouter(App);
