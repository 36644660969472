import React, { Fragment } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@layout/SocialMedia.css";

const SocialMedia = () => {
  return (
    <Fragment>
      <div className="sm-background d-flex justify-content-center fixed-bottom">
        <Tooltip arrow title="Facebook">
          <IconButton
            className="nav-link"
            href="https://www.facebook.com/letstangerine"
            rel="noopener nofollow"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={["fab", "facebook"]}
              className="font-size-xxl"
            />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Twitter">
          <IconButton
            className="nav-link"
            href="https://www.twitter.com/letstangerine"
            rel="noopener nofollow"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={["fab", "twitter"]}
              className="font-size-xxl"
            />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Instagram">
          <IconButton
            className="nav-link"
            href="https://www.instagram.com/letstangerine"
            rel="noopener nofollow"
            target="_blank"
          >
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon
                icon={["fab", "instagram"]}
                className="font-size-xxl"
              />
            </span>
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Pinterest">
          <IconButton
            className="nav-link"
            href="https://www.pinterest.com/letstangerine"
            rel="noopener nofollow"
            target="_blank"
          >
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon
                icon={["fab", "pinterest"]}
                className="font-size-xxl"
              />
            </span>
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="TikTok">
          <IconButton
            className="nav-link"
            href="https://www.tiktok.com/@letstangerine"
            rel="noopener nofollow"
            target="_blank"
          >
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon
                icon={["fab", "tiktok"]}
                className="font-size-xxl"
              />
            </span>
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="LinkedIn">
          <IconButton
            className="nav-link"
            href="https://www.linkedin.com/company/letstangerine"
            rel="noopener nofollow"
            target="_blank"
          >
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon
                icon={["fab", "linkedin"]}
                className="font-size-xxl"
              />
            </span>
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="YouTube">
          <IconButton
            className="nav-link"
            href="https://www.youtube.com/channel/UCAQdtlEfK4S0IhDYJLxJWPA/videos"
            rel="noopener nofollow"
            target="_blank"
          >
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon
                icon={["fab", "youtube"]}
                className="font-size-xxl"
              />
            </span>
          </IconButton>
        </Tooltip>
      </div>
    </Fragment>
  );
};

export default SocialMedia;
