import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";

// var inShape = PropTypes.shape({
//   opacity: PropTypes.number.isRequired,
//   scale: PropTypes.number.isRequired,
// });

// export const PageVariants = PropTypes.shape({
//   in: inShape.isRequired,
//   initial: inShape.isRequired,
//   out: inShape.isRequired,
// }).isRequired;

// export const PageTransition = PropTypes.shape({
//   duration: PropTypes.number.isRequired,
//   ease: PropTypes.string.isRequired,
//   type: PropTypes.string.isRequired,
// }).isRequired;

export const RouterProps = PropTypes.shape({
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
}).isRequired;

export const Children = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]).isRequired;
